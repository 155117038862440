<template>
  <div>
    <!-- <van-sticky position="top">
      <van-nav-bar
        :title="pageHead"
        @click-right="onClickRight"
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" :size="25"/>
        </template>
        <template #right>
          <van-icon name="volume-o" :size="25"/>
        </template>
      </van-nav-bar>
    </van-sticky> -->
    <router-view class="bg"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'Layout',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(
      {pageHead: "getPageHead"}
    )
  },
  methods: {
    onClickRight() {
      this.$router.push({path: '/xiaoxi'})
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  },
}
</script>

<style scoped>
.bg {
  background-image: linear-gradient(179deg, #6C94F6 0%, #436EF0 16%, #3A5BE9 100%);
}
</style>
